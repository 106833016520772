<template>
  <div>

  <!-- <div class="head mb-2 d-flex justify-content-between align-align-items-center">
    <span class="fs-5">
      <i class="bi bi-building pr-2" style="color: #0d6efd"></i>
      资产管理
    </span>
      <div>
        <router-link class="ml-3" to="/estate/community" active-class="fw-bold text-primary">小区资产</router-link>
        <router-link class="ml-3" to="/estate/pump-house" active-class="fw-bold text-primary">泵房资产</router-link>
        <router-link to="/estate/community" active-class="fw-bold text-primary">生命周期管理</router-link>

        <router-link to="/" class="ml-3">
          <i class="bi bi-geo-alt"></i>
          <span>泵房导航</span>
        </router-link>

      </div>
    </div> -->


    <keep-alive>
      <transition name="fade">
      <router-view />
      </transition>
    </keep-alive>

  </div>
</template>

<script>
export default {
  name: 'Estate'
}
</script>

<style>

</style>